`use strict`;

import Xhr from '../libs/xhr';
//import Lazyload from '../libs/lazyload';

class Products {
    constructor() {
        window.selectors = {
            productImage: `.js-product-image`,
            filtersSearch: `.js-filters-search`,
            productsWrapper: `.js-products-wrapper`,
            productsWinesWrapperAjax: `.js-ajax-products-wrapper`,
            sortOils: `.js-sort-oil`,
            sortCream: `.js-sort-cream`,
            sortJuice: `.js-sort-juice`,
            sortPowder: `.js-sort-powder`,
            quantity: `.js-add-to-cart-quantity`
        };

        /*let lazyload = new Lazyload();
        lazyload.initLazyLoading();*/

        this.initFilterProductSearchListener();
        // this.initFilterCreamProductsSearchListener();
        // this.initFilterOilProductsSearchListener();
        // this.initFilterYourWineSearchListener();
        this.initChangeQuantityOfProduct();
        this.initShowHideFiltersListener();

        if (document.querySelector('.js-show-filters')) {
            this.initCountActiveFilters(document.querySelector('.js-show-filters').getAttribute('data-type'));
        }
    }

    initChangeQuantityOfProduct() {
        $('.js-add-quantity').on('click', function() {
            let el = $(window.selectors.quantity);
            let qty = parseInt(el.val());
            if (qty < 100) {
                el.val(qty + 1);
            }
        });

        $('.js-remove-quantity').on('click', function() {
            let el = $(window.selectors.quantity);
            let qty = parseInt(el.val());
            if (qty > 1) {
                el.val(qty - 1);
            }
        });
    }

    initCountActiveFilters(type) {
        let activeFiltersIndicator = document.querySelector('.js-active-filters-number');
        let countFilters = 0;

        switch(type) {
            case 1:
                countFilters = document.querySelectorAll('.js-filters-search:checked').length;
            break;
            case 2:
                countFilters = document.querySelectorAll('.js-filters-cream-search:checked').length;
            break;
            case 3:
                countFilters = document.querySelectorAll('.js-filters-offer-search:checked').length;
            break;
        }
        activeFiltersIndicator.innerHTML = '(' + countFilters + ')';
    }

    initShowHideFiltersListener() {
        let btnShowFilters = document.querySelector('.js-show-filters');
        let btnCloseFilters = document.querySelector('.js-filters-close');
        let modalFilters = document.querySelector('.js-product-filters');
        let self = this;

        $(btnShowFilters).on('click', function() {
            $(modalFilters).addClass('active');
            let filterType = $(this).data('type');
            self.initCountActiveFilters(filterType);
        });

        $(btnCloseFilters).on('click', function() {
            $(modalFilters).removeClass('active');
            let filterType = $(this).data('type');
            self.initCountActiveFilters(filterType);
        });
    }

    initFilterProductSearchListener() {
        let types = [];
        let styles = [];
        let ranges = [];
        let prices = [];
        let discounts = [];
        let cashbacks = [];
        let params = '';

        let fnc = function () {
            types = [];
            styles = [];
            ranges = [];
            prices = [];
            discounts = [];
            cashbacks = [];
            params = '';

            $('.js-filters-search:checked').each(function() {
                if ($(this).hasClass('js-type')) {
                    types.push($(this).val());
                }

                if ($(this).hasClass('js-style')) {
                    styles.push($(this).val());
                }

                if ($(this).hasClass('js-range')) {
                    ranges.push($(this).val());
                }

                if ($(this).hasClass('js-price')) {
                    prices.push($(this).val());
                }

                if ($(this).hasClass('js-discount')) {
                    discounts.push($(this).val());
                }

                if ($(this).hasClass('js-cashback')) {
                    cashbacks.push($(this).val());
                }
            });

            let sortField =
                undefined !==
                $(window.selectors.sortOils)
                    .find(':selected')
                    .data('sortField')
                    ? $(window.selectors.sortOils)
                          .find(':selected')
                          .data('sortField')
                    : 'price';
            let sortType =
                undefined !==
                $(window.selectors.sortOils)
                    .find(':selected')
                    .data('sortType')
                    ? $(window.selectors.sortOils)
                          .find(':selected')
                          .data('sortType')
                    : 'asc';

            params = '?types=' + types + '&styles=' + styles + '&ranges=' + ranges + '&prices=' + prices + '&discounts=' + discounts + '&cashbacks=' + cashbacks + '&sortField=' + sortField + '&sortType=' + sortType;

            let pageUrl = window.location.href + params;

            if (pageUrl !== window.location.href) {
                window.history.pushState(
                    {
                        types,
                        styles,
                        ranges,
                        prices,
                        discounts,
                        cashbacks,
                        sortField,
                        sortType
                    },
                    '',
                    params
                );
            }

            return new Promise((resolve, reject) => {
                let options = {
                    url: window.Laravel.filterProductsFromCategoriesURL,
                    type: 'GET',

                    data: {
                        types: types,
                        styles: styles,
                        ranges: ranges,
                        prices: prices,
                        discounts: discounts,
                        cashbacks: cashbacks,
                        type: $('#type').val(),
                        style: $('#style').val(),
                        range: $('#range').val(),
                        pageID: $('#pageID').val(),
                        sortField: sortField,
                        sortType: sortType
                    },

                    beforeSend: function() {
                        $('.loader-overlay').show();
                    },

                    success: function(data) {
                        if (!data) {
                            this.error(this);
                        } else if (data.status === 'OK') {
                            $(window.selectors.productsWrapper).hide();

                            $(window.selectors.productsWinesWrapperAjax)
                                .show()
                                .html(data.message);

                            // self.initEffectOnProductListeners();

                            $('.loader-overlay').hide();
                        } else if (data.status === 'ERROR') {
                            reject(data.message);
                            return;
                        }
                    },

                    complete: function() {
                        $('.loader-overlay').hide();
                    },

                    error: function(xhr) {
                        console.log(xhr);
                        reject('An error has occurred on products searching');
                    }
                };

                let xhr = new Xhr();
                xhr.setOptions(options);
                xhr.execute();
            });
        }

        $('.js-filters-search').on('click', function(e) {
            fnc();
        });

        $('.js-sort-oil').on('change', function(e) {
            fnc();
        });
    }

    // ---------------------------------PENTRU FILTRARILE DIN PARTEA DREAPTA------------------------------------------
    // momentan comentate pentru ca sunt prea putine produse - trebuie completat pentru toate categoriile

    // initFilterCreamProductsSearchListener() {
    //     let types = [];
    //     let prices = [];
    //     let params = '';
    //
    //     let fnc = function () {
    //         types = [];
    //         prices = [];
    //         params = '';
    //
    //         $('.js-filters-cream-search:checked').each(function () {
    //             if ($(this).hasClass('js-type')) {
    //                 types.push($(this).val());
    //             }
    //
    //             if ($(this).hasClass('js-price')) {
    //                 prices.push($(this).val());
    //             }
    //         });
    //
    //         let sortField = $(window.selectors.sortCream)
    //             .find(':selected')
    //             .data('sortField');
    //         let sortType = $(window.selectors.sortCream)
    //             .find(':selected')
    //             .data('sortType');
    //
    //         params = '?types=' + types + '&prices=' + prices + '&sortField=' + sortField + '&sortType=' + sortType;
    //
    //         let pageUrl = window.location.href + params;
    //
    //         if (pageUrl !== window.location.href) {
    //             window.history.pushState(
    //                 {
    //                     types,
    //                     prices,
    //                     sortField,
    //                     sortType
    //                 },
    //                 '',
    //                 params
    //             );
    //         }
    //
    //         return new Promise((resolve, reject) => {
    //             let options = {
    //                 url: window.Laravel.filterAccURL,
    //                 type: 'GET',
    //
    //                 data: {
    //                     types: types,
    //                     prices: prices,
    //                     type: $('#type').val(),
    //                     style: $('#style').val(),
    //                     pageID: $('#pageID').val(),
    //                     sortField: $(window.selectors.sortCream)
    //                         .find(':selected')
    //                         .data('sortField'),
    //                     sortType: $(window.selectors.sortCream)
    //                         .find(':selected')
    //                         .data('sortType')
    //                 },
    //
    //                 beforeSend: function () {
    //                     $('.loader-overlay').show();
    //                 },
    //
    //                 success: function (data) {
    //                     if (!data) {
    //                         this.error(this);
    //                     } else if (data.status === 'OK') {
    //                         $(window.selectors.productsWrapper).hide();
    //
    //                         $(window.selectors.productsWinesWrapperAjax)
    //                             .show()
    //                             .html(data.message);
    //
    //                         // self.initEffectOnProductListeners();
    //                         /*let lazyload = new Lazyload();
    //                         lazyload.initLazyLoading();*/
    //
    //                         $('.loader-overlay').hide();
    //                     } else if (data.status === 'ERROR') {
    //                         reject(data.message);
    //                         return;
    //                     }
    //                 },
    //
    //                 complete: function () {
    //                     $('.loader-overlay').hide();
    //                 },
    //
    //                 error: function (xhr) {
    //                     console.log(xhr);
    //                     reject('An error has occurred on products searching');
    //                 }
    //             };
    //
    //             let xhr = new Xhr();
    //             xhr.setOptions(options);
    //             xhr.execute();
    //         });
    //     }
    //
    //     $('.js-filters-cream-search').on('click', function(e) {
    //         fnc();
    //     });
    //
    //     $('.js-sort-cream').on('change', function(e) {
    //         fnc();
    //     });
    // }

    // initFilterOilProductsSearchListener() {
    //     let types = [];
    //     let prices = [];
    //     let params = '';
    //
    //     let fnc = function () {
    //         types = [];
    //         prices = [];
    //         params = '';
    //
    //         $('.js-filters-offer-search:checked').each(function () {
    //             if ($(this).hasClass('js-type')) {
    //                 types.push($(this).val());
    //             }
    //
    //             if ($(this).hasClass('js-price')) {
    //                 prices.push($(this).val());
    //             }
    //         });
    //
    //         let sortField = $(window.selectors.sortOils)
    //             .find(':selected')
    //             .data('sortField');
    //         let sortType = $(window.selectors.sortOils)
    //             .find(':selected')
    //             .data('sortType');
    //
    //         params = '?types=' + types + '&prices=' + prices + '&sortField=' + sortField + '&sortType=' + sortType;
    //
    //         let pageUrl = window.location.href + params;
    //
    //         if (pageUrl !== window.location.href) {
    //             window.history.pushState(
    //                 {
    //                     types,
    //                     prices,
    //                     sortField,
    //                     sortType
    //                 },
    //                 '',
    //                 params
    //             );
    //         }
    //
    //         return new Promise((resolve, reject) => {
    //             let options = {
    //                 url: window.Laravel.filterOfferURL,
    //                 type: 'GET',
    //
    //                 data: {
    //                     types: types,
    //                     prices: prices,
    //                     type: $('#type').val(),
    //                     style: $('#style').val(),
    //                     pageID: $('#pageID').val(),
    //                     sortField: $(window.selectors.sortOils)
    //                         .find(':selected')
    //                         .data('sortField'),
    //                     sortType: $(window.selectors.sortOils)
    //                         .find(':selected')
    //                         .data('sortType')
    //                 },
    //
    //                 beforeSend: function () {
    //                     $('.loader-overlay').show();
    //                 },
    //
    //                 success: function (data) {
    //                     if (!data) {
    //                         this.error(this);
    //                     } else if (data.status === 'OK') {
    //                         $(window.selectors.productsWrapper).hide();
    //
    //                         $(window.selectors.productsWinesWrapperAjax)
    //                             .show()
    //                             .html(data.message);
    //
    //                         // self.initEffectOnProductListeners();
    //                         /*let lazyload = new Lazyload();
    //                         lazyload.initLazyLoading();*/
    //
    //                         $('.loader-overlay').hide();
    //                     } else if (data.status === 'ERROR') {
    //                         reject(data.message);
    //                         return;
    //                     }
    //                 },
    //
    //                 complete: function () {
    //                     $('.loader-overlay').hide();
    //                 },
    //
    //                 error: function (xhr) {
    //                     console.log(xhr);
    //                     reject('An error has occurred on products searching');
    //                 }
    //             };
    //
    //             let xhr = new Xhr();
    //             xhr.setOptions(options);
    //             xhr.execute();
    //         });
    //     }
    //
    //     $('.js-filters-offer-search').on('click', function(e) {
    //         fnc();
    //     });
    //
    //     $('.js-sort-oil').on('change', function(e) {
    //         fnc();
    //     });
    // }


    // initFilterYourWineSearchListener() {
    //     let self = this;
    //
    //     let types = [];
    //     let prices = [];
    //     let params = '';
    //
    //     let fnc = function () {
    //         types = [];
    //         prices = [];
    //         params = '';
    //
    //         $('.js-filters-powder-search:checked').each(function () {
    //             if ($(this).hasClass('js-type')) {
    //                 types.push($(this).val());
    //             }
    //
    //             if ($(this).hasClass('js-price')) {
    //                 prices.push($(this).val());
    //             }
    //         });
    //
    //         let sortField = $(window.selectors.sortPowder)
    //             .find(':selected')
    //             .data('sortField');
    //         let sortType = $(window.selectors.sortPowder)
    //             .find(':selected')
    //             .data('sortType');
    //
    //         params = '?types=' + types + '&prices=' + prices + '&sortField=' + sortField + '&sortType=' + sortType;
    //
    //         let pageUrl = window.location.href + params;
    //
    //         if (pageUrl !== window.location.href) {
    //             window.history.pushState(
    //                 {
    //                     types,
    //                     prices,
    //                     sortField,
    //                     sortType
    //                 },
    //                 '',
    //                 params
    //             );
    //         }
    //
    //         return new Promise((resolve, reject) => {
    //             let options = {
    //                 url: window.Laravel.filterProductsURL,
    //                 type: 'GET',
    //
    //                 data: {
    //                     types: types,
    //                     prices: prices,
    //                     type: $('#type').val(),
    //                     style: $('#style').val(),
    //                     pageID: $('#pageID').val(),
    //                     sortField: $(window.selectors.sortPowder)
    //                         .find(':selected')
    //                         .data('sortField'),
    //                     sortType: $(window.selectors.sortPowder)
    //                         .find(':selected')
    //                         .data('sortType')
    //                 },
    //
    //                 beforeSend: function () {
    //                     $('.loader-overlay').show();
    //                 },
    //
    //                 success: function (data) {
    //                     if (!data) {
    //                         this.error(this);
    //                     } else if (data.status === 'OK') {
    //                         $(window.selectors.productsWrapper).hide();
    //
    //                         $(window.selectors.productsWinesWrapperAjax)
    //                             .show()
    //                             .html(data.message);
    //
    //                         // self.initEffectOnProductListeners();
    //                         /*let lazyload = new Lazyload();
    //                         lazyload.initLazyLoading();*/
    //
    //                         $('.loader-overlay').hide();
    //                     } else if (data.status === 'ERROR') {
    //                         reject(data.message);
    //                         return;
    //                     }
    //                 },
    //
    //                 complete: function () {
    //                     $('.loader-overlay').hide();
    //                 },
    //
    //                 error: function (xhr) {
    //                     console.log(xhr);
    //                     reject('An error has occurred on products searching');
    //                 }
    //             };
    //
    //             let xhr = new Xhr();
    //             xhr.setOptions(options);
    //             xhr.execute();
    //         });
    //     }
    //
    //     $('.js-filters-powder-search').on('click', function(e) {
    //         fnc();
    //     });
    //
    //     $('.js-sort-powder').on('change', function(e) {
    //         fnc();
    //     });
    // }
}

new Products();
